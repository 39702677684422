/* eslint-disable max-len */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faUserCog,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";

export const CharterPM = (props) => (
  <div className="charter-div style-background-components">
    <header>
      <h2>Project Charter</h2>
    </header>
    <p>
      <FontAwesomeIcon icon={faBookmark} className="icons-presentation" />
      <strong>Project:</strong> Alliance Prototype
      <br />
      <FontAwesomeIcon icon={faFemale} className="icons-presentation" />
      <strong>Customer:</strong> Kristine Olson, VP Market Development
      <br />
      <FontAwesomeIcon icon={faUserCog} className="icons-presentation" />
      <strong>Sponsor:</strong> B. D. Smith, President & COO
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Description
    </h4>
    <p>
      The Alliance Launch project is part of the marketing effort for a much
      bigger and complicated effort called The Alliance Project. The Alliance
      Project represents a major strategic effort to augment the sales and
      marketing capabilities of Uniworld with an e-commerce equipped web site.
      This web site is needed to help move Uniworld into the e-world and to help
      it regain its global market dominance. The Alliance Launch project will
      generate the marketing materials that will be used to advertise the new
      Uniworld e- commerce web site. Included within these materials will be a
      tutorial that will teach current and prospective end users how to navigate
      and use the new web site for researching and ordering Uniworld products.
      The Alliance Launch project is therefore dependent on the Alliance project
      for all required information about the new web site. The current status of
      the Alliance project indicates no schedule impact at this time for the
      Alliance Launch project. The customer of this marketing material effort is
      the sales and marketing organization of Uniworld. The end user is
      Uniworlds's current and prospective product customers. They will have
      input into the Launch materials and provide important feedback during the
      project. The company has budgeted $500,000 dollars for the development of
      the launch materials. This amount includes all labor, materials, printing,
      mailing, and production. Marketing has stated that it needs the material
      mailed within 20 weeks in order to coordinate the mailing with the launch
      of the Alliance web site. The Alliance Launch Product is to be composed of
      printed marketing materials and a tutorial that will introduce and teach
      the user how to use the new Uniworld web site.
    </p>   
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Deliverables
    </h4>
    <p>
      The deliverables for this project are:
      <ul>
        <li>Marketing materials</li>
        <li>Tutorial</li>
        <li>Mailing materials</li>
      </ul>
      This project is not expected to:
      <ul>
        <li>Produce the end user mailing list</li>
        <li>Actually do the printing or mailing</li>
        <li>Produce the actual Alliance web site design</li>
        <li>Perform follow-on analysis</li>
        <li>Perform follow-on marketing or sales</li>
      </ul>
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Performance
    </h4>
    <p>
      The project will be deemed to have met quality requirements when system
      testing verifies there are less than 20 total defects in the tutorial and
      draft printed materials. The tutorial must be capable of installing and
      launching in less than 10 seconds on all the major PC and Macintosh
      platforms.
      <br />
      Cordially, <br />
      B. D. Smith, President & COO
    </p>
  </div>
);
